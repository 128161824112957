export default {
  thor: {
    moduleCode: {
      production: 'SUB_STORMBORN',
      development: 'SUB_STORMBORN',
    },
    programId: null,
  },
  api: {
    campaign: {
      url: {
        // UAT rewards
        production: 'https://rewards.subway.co.uk/tx-sub/campaign/enrol',
        development: 'https://rewards.subway.co.uk/tx-sub/campaign/enrol',
        // Live rewards
        // production: 'https://rewards.subway.co.uk',
        // development: 'https://rewards.subway.co.uk',
      },
    },
    tranxactor: {
      url: {
        production: 'https://rewards.subway.co.uk/tx-auth',
        development: 'https://rewards.subway.co.uk/tx-auth',
      },
    },
    cms: {
      url: {
        // production:
        //   'http://ec2-52-214-88-40.eu-west-1.compute.amazonaws.com:1337',
        // development:
        //   'http://ec2-52-214-88-40.eu-west-1.compute.amazonaws.com:1337',
        //Live
        // production: 'https://strapi-sub.tranxactor.com',
        // development: 'https://strapi-sub.tranxactor.com',
        //Testing
        production: 'https://strapi-sub-orig.tranxactor.com/',
        development: 'https://strapi-sub-orig.tranxactor.com/',
      },
    },
    stormborn: {
      url: {
        production: 'https://rewards.subway.co.uk/tx-auth',
        development: 'https://rewards.subway.co.uk/tx-auth',
        mockapi: 'https://rewards.subway.co.uk/mobilegateway',
        api: 'https://rewards.subway.co.uk/tx-sub',
      },
    },
    onlineOrder: {
      url: {
        production: 'https://order.subway.co.uk',
        development: 'https://order.subway.co.uk',
      },
    },
    subcard: {
      url: {
        production: 'https://rewards.subway.co.uk',
        development: 'https://rewards.subway.co.uk',
      },
    },
    subway: {
      url: {
        production: 'https://subway.tranxactor.com',
        development: 'https://subway.tranxactor.com',
      },
    },
  },
}
